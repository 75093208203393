export function encodeParams(params) {
  return params.map(x => {
    const initialValue = x.substring(x.indexOf('=') + 1);
    const encodedValue = encodeURIComponent(decodeURIComponent(initialValue));
    return x.replace(initialValue, encodedValue);
  }).join('&');
}

// send message for get form page url and get referrer widgets
export function sendMessageToWidgets(e, iframe, permittedDomains) {
  const isJotForm = window.isPermitted(e.origin, permittedDomains);
  if (
    isJotForm
      && 'contentWindow' in iframe
      && 'postMessage' in iframe.contentWindow
  ) {
    const urls = {
      docurl: encodeURIComponent(document.URL),
      referrer: encodeURIComponent(document.referrer)
    };
    iframe.contentWindow.postMessage(
      JSON.stringify({ type: 'urls', value: urls }),
      '*'
    );
  }
}

function exitFullscreen() {
  if (window.document.exitFullscreen) window.document.exitFullscreen();
  else if (window.document.mozCancelFullScreen) window.document.mozCancelFullScreen();
  else if (window.document.mozCancelFullscreen) window.document.mozCancelFullScreen();
  else if (window.document.webkitExitFullscreen) window.document.webkitExitFullscreen();
  else if (window.document.msExitFullscreen) window.document.msExitFullscreen();
}

export function handleMessageTypes(args, e, permittedDomains, iframeSelector) {
  const iframe = document.querySelector(iframeSelector);
  switch (args[0]) {
    case 'scrollIntoView':
      iframe.scrollIntoView();
      break;
    case 'setHeight':
      iframe.style.height = `${args[1]}px`;
      if (
        !Number.isNaN(args[1])
          && parseInt(iframe.style.minHeight, 10) > parseInt(args[1], 10)
      ) {
        iframe.style.minHeight = `${args[1]}px`;
      }
      break;
    case 'setMinHeight':
      iframe.style.minHeight = `${args[1]}px`;
      break;
    case 'collapseErrorPage':
      if (iframe.clientHeight > window.innerHeight) {
        iframe.style.height = `${window.innerHeight}px`;
      }
      break;
    case 'reloadPage':
      window.location.reload();
      break;
    case 'removeIframeOnloadAttr':
      iframe.removeAttribute('onload');
      break;
    case 'loadScript':
      if (!window.isPermitted(e.origin, permittedDomains)) {
        break;
      }
      const script = document.createElement('script');
      script.src = (args.length > 3) ? `${args[1]}:${args[2]}` : args[1];
      script.type = 'text/javascript';
      document.body.appendChild(script);
      break;
    case 'exitFullscreen':
      exitFullscreen();
      break;
    case 'setDeviceType':
      const [, deviceType] = args;
      window.jfDeviceType = deviceType;
      break;
    case 'backgroundStyles':
      const backgroundStyles = new URLSearchParams(args[1]);
      backgroundStyles.forEach((value, key) => {
        iframe.style[key] = value;
      });
      const doc = iframe.contentDocument ? iframe.contentDocument : (iframe.contentWindow.document || iframe.document);
      doc.documentElement.style.background = 'none';
      break;
    default:
      break;
  }
}
